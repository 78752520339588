const isDev =
  process.env.NODE_ENV === "development" ||
  process.env.REACT_APP_DOCKER_ENV === "development";

const config = {
  isDev,

  name: "eShopRocket",
  companyName: "ProApp",
  supportEmail: "info@eshoprocket.com",

  price: {
    from: "1%",
    to: "8%",
  },

  // wordpress
  wpProtocol: isDev ? "http:" : "https:",
  wpDomain: isDev ? "localhost" : "eshoprocket.com",

  server: {
    wpApiNamespace: "wp/v2",
    wcApiNamespace: "wc/v3",
    shopApiNamespace: "shop-api/v1",
  },

  // function
  functionUrl: isDev ? "http://localhost:10000" : "https://fn.eshoprocket.com",

  // function
  webUrl: isDev ? "http://localhost:3001" : "https://eshoprocket.com",

  // supabase
  supabaseUrl: isDev ? "http://localhost:8000" : "https://kon.eshoprocket.com",
  supabaseAnonKey:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.ewogICAgInJvbGUiOiAiYW5vbiIsCiAgICAiaXNzIjogInN1cGFjYXJ0IiwKICAgICJpYXQiOiAxNjc1MTgwODAwLAogICAgImV4cCI6IDIwODU0MDgwMDAKfQ.63yv71lMYAkiD0_V0elqzf2e4Ody6eBGm14LdmiXWr4",
};

export default config;
