import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Routes, Route } from "react-router-dom";

import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import { useMaterialUIController } from "context";
import AuthLayout from "layouts/AuthLayout";
import Dashboard from "routes/u/Dashboard/Dashboard";
import Authorize from "routes/Authorize";
import ResetPassword from "routes/ResetPassword";
import ForgotPassword from "routes/ForgotPassword";
import SignIn from "routes/SignIn";
import SignUp from "routes/SignUp";
import SignOut from "routes/SignOut/SignOut";
import ScrollToTop from "routes/ScrollToTop";
import Orders from "routes/u/Orders/Orders";
import { RichTextEditorsProvider } from "components/RichTextEditor/useRichTextEditors";
import "i18n/i18n";
import LocationHistory from "components/LocationHistory";

function App() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <RichTextEditorsProvider>
        <CssBaseline />
        <ScrollToTop />
        <LocationHistory />
        <Routes>
          <Route path="/">
            <Route index element={<SignIn />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="authorize" element={<Authorize />} />
            <Route path="signin" element={<SignIn />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="signout" element={<SignOut />} />
          </Route>
          <Route path="/u/" element={<AuthLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="orders" element={<Orders />} />
          </Route>
        </Routes>
      </RichTextEditorsProvider>
    </ThemeProvider>
  );
}

export default App;
